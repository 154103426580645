import React, { useCallback, useContext } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import apiClient from "../apiClient";
import { isProduction } from "../utils/envUtils";
import { CartContext } from "../CartContext";

const spk = isProduction() ? "pk_live_51ODWEUC9IgQQTpICWO6y2Qhfl1BXC88IW9zL4FeRjqLqjrfD2eemP8i7Wj30YpbnytzecxMr1ag5QDZimfe6fhqY00N8tHUKMf" : "pk_test_51ODWEUC9IgQQTpICDSteYPol9PuqYCDHRu8pi85dm6ChnJZji821G3JywZl4CwhXSPCWDwVDyUSBtO7rcOEjULMo001Z4woYzX";
const stripePromise = loadStripe(spk);

const CheckoutForm = () => {
    const { cart, address, discountCode } = useContext(CartContext)
    const fetchClientSecret = useCallback(() => {
        const requestBody = {
            address: {
                name: address.firstName + ' ' + address.lastName,
                street1: address.street1,
                street2: address.street2 || null,
                city: address.city || null,
                state: address.state,
                postcode: address.postcode,
                country: address.country,
            },
            line_items: cart.map(book => ({
                prompt: book.prompt,
                age: book.age || null,
                child_first_name: book.child_first_name || null,
                gender: book.gender || null,
                title: book.title || null,
                race: book.race || null,
                img_id: book.img_id || null,
                product_type: book.product_type || null,
                physical_type: book.physical_type || null,
                quantity: book.quantity,
            })),
            discountCode
        };

        return apiClient.post('/create-checkout-session', requestBody)
            .then((res) => res.data.clientSecret);
    }, [cart, address, discountCode]);

    const options = {
        fetchClientSecret
    };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
                key={discountCode}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};

export default CheckoutForm;