// src/CartContext.jsx

import React, { createContext, useState, useEffect } from "react";
import apiClient from "./apiClient";
import { v4 as uuidv4 } from "uuid";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem("cart");
        return savedCart ? JSON.parse(savedCart) : [];
    });

    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountCode, setDiscountCode] = useState(null);
    const [globalDiscountCode, setGlobalDiscountCode] = useState(null);
    const [globalDiscountPercentage, setGlobalDiscountPercentage] = useState(null);

    useEffect(() => {
        const fetchPromo = async () => {
            try {
                const response = await apiClient.get('/active-promo', {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200 && response.data) {
                    if (response.data.isActive) {
                        const respDiscountCode = response.data.promo.code;
                        const respDiscountPercentOff = response.data.promo.percentOff;
                        setDiscountPercentage(respDiscountPercentOff);
                        setDiscountCode(respDiscountCode);
                        setGlobalDiscountCode(respDiscountCode);
                        setGlobalDiscountPercentage(respDiscountPercentOff);
                    }
                }
            } catch (error) {
                console.error("Error fetching promo:", error);
            }
        };

        fetchPromo();
    }, []);


    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    useEffect(() => {

    }, []);

    const [address, setAddress] = useState(() => {
        const savedAddress = localStorage.getItem("address");
        return savedAddress
            ? JSON.parse(savedAddress)
            : {
                firstName: "",
                lastName: "",
                street1: "",
                street2: "",
                city: "",
                state: "",
                postcode: "",
                country: "",
            };
    });

    useEffect(() => {
        localStorage.setItem("address", JSON.stringify(address));
    }, [address]);

    const addToCart = (item) => {
        const newItem = { ...item, id: uuidv4() };
        setCart((prevCart) => [...prevCart, newItem]);
    };

    const removeFromCart = (itemId) => {
        setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
    };

    const clearCart = () => {
        setCart([]);
    };

    const clearAddress = () => {
        setAddress({
            firstName: "",
            lastName: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            postcode: "",
            country: "",
        });
    };

    const updateCartQuantity = (itemId, newQuantity) => {
        setCart((prevCart) =>
            prevCart.map((item) =>
                item.id === itemId ? { ...item, quantity: newQuantity } : item
            )
        );
    };

    // --- Expose a method to update address from Google Autocomplete ---
    const updateAddress = (newAddress) => {
        setAddress(newAddress);
    };

    const removeDiscount = () => {
        setDiscountCode("");
        setDiscountPercentage(0);
    };

    const getDiscountedPrice = (item) => {
        const basePrice = item.price || 0;

        if (discountPercentage > 0) {
            // Use the discount code from context
            const discountValue = (discountPercentage / 100) * basePrice;
            return (basePrice - discountValue).toFixed(2);
        } else if (item.discountPercentage) {
            // Fallback to the item-based discount
            const discountValue = (item.discountPercentage / 100) * basePrice;
            return (basePrice - discountValue).toFixed(2);
        }
        // No discount
        return basePrice.toFixed(2);
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                addToCart,
                updateCartQuantity,
                removeFromCart,
                clearCart,
                address,
                updateAddress,
                clearAddress,
                discountCode,
                discountPercentage,
                removeDiscount,
                setDiscountCode,
                setDiscountPercentage,
                getDiscountedPrice,
                globalDiscountCode,
                globalDiscountPercentage
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
