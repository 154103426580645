import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Container from '@mui/material/Container';

const mock = [
  {
    title: 'Digital',
    subtitle: 'Perfect for a story you need right now',
    price: { annual: '$29.95' },
    features: ['Immediate access', "Completely Unique"],
    isHighlighted: false,
  },
  {
    title: 'Paperback',
    subtitle: 'Perfect mix between the two',
    price: { annual: '$49.95' },
    features: ['Includes Digital Copy', 'Paperback Cover', 'Premium Paper', 'Premium Color Ink',],
    isHighlighted: true,
  },
  {
    title: 'Hardcover',
    subtitle: 'Ideal for keep-sakes & gifts',
    price: { annual: '$59.95' },
    features: [
      'Includes Digital Copy',
      'Extra Durable',
      'Premium Paper',
      'Premium Color Ink',
      'Hard Cover',
    ],
    isHighlighted: false,
  },
];

const WithOptionTogglerButton = () => {
  // We’ll fix the pricing to "annual" by default (as if the toggler is set to 'annual')
  const pricingOption = 'annual';

  return (
    <Box sx={{ pb: 4 }}>
      {/* Top section with background gradient and main text */}
      <Box
        sx={{
          position: 'relative',
          backgroundColor: '#f5f5f5',
          backgroundImage: 'linear-gradient(120deg, #ffffff 0%, #eaeaea 100%)',
          py: 8, // add some vertical padding
        }}
      >
        <Container>
          <Box textAlign="center" mb={4}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontWeight: 900 }}
            >
              Pricing
            </Typography>
            <Typography variant="h6" sx={{ color: '#424242' }}>
              Below are the prices for each book category
              <br />
              Prices do not include shipping
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Pricing Cards */}
      <Container>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Card
                variant="outlined"
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box mb={2}>
                    <Typography variant="h4" fontWeight={600} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography style={{ color: '#757575' }}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="baseline" mb={2}>
                    <Typography variant="h3" fontWeight={700} mr={1}>
                      {pricingOption === 'annual'
                        ? item.price.annual
                        : item.price.monthly}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ color: '#757575' }}
                      fontWeight={700}
                    >
                      {pricingOption === 'annual' ? '' : '/mo'}
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    {item.features.map((feature, j) => (
                      <Grid item xs={12} key={j}>
                        <ListItem disableGutters sx={{ p: 0 }}>
                          <ListItemAvatar sx={{ minWidth: 'auto', mr: 2 }}>
                            <Avatar
                              sx={{
                                width: 20,
                                height: 20,
                                backgroundColor: '#1976d2',
                              }}
                            >
                              <svg
                                width={12}
                                height={12}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={feature} />
                        </ListItem>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
                <Box flexGrow={1} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WithOptionTogglerButton;
