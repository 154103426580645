import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors';

const Hero = () => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      sx={{
        width: '100%',
        maxWidth: '100%',
        minHeight: 700,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: {
          xs: 'url("/images/book_hero_img_mobile.jpg")', // Mobile image
          md: 'url("/images/book_hero_img.jpg")', // Desktop image
        },
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >
      {/* LEFT SIDE: text + button */}
      <Box
        sx={{
          flex: {
            xs: '1 1 auto',
            md: '0 1 50%',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: {
            xs: 'flex-end',
            md: 'center',
          },
          alignItems: 'center',
          p: 2,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          color="white"
          sx={{
            mb: { md: 4 },
            fontWeight: 600,
          }}
        >
          <Typography
            variant="h3"
            color="white"
            sx={{
              fontWeight: 1000,
            }}
          >
            Spark imaginations with{' '}
            <Typography
              color={'#f7faff'}
              component={'span'}
              variant={'inherit'}
              sx={{
                background: `linear-gradient(180deg, transparent 82%, ${alpha(
                  theme.palette.secondary.main,
                  0.3,
                )} 0%)`,
                color: lightBlue[100],
              }}
            >
              unique stories
            </Typography>
          </Typography>
        </Typography>

        {/* Move divider and text to bottom on mobile */}
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            mt: { xs: 'auto', md: 0 },
          }}
        >
          <Divider
            sx={{
              borderColor: 'white',
              borderBottomWidth: 2,
              my: 2,
              width: '100%',
              mx: 'auto',
            }}
          />

          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', pb: 4 }}
            color={'#f7faff'}
          >
            Create your own personalized book that tells the story you want.
          </Typography>
        </Box>

        <Button
          variant="contained"
          size="large"
          href="/children-books"
          fullWidth
          sx={{
            animation: 'glow 1.5s infinite alternate',
            '@keyframes glow': {
              '0%': { boxShadow: '0 0 5px #fff' },
              '100%': { boxShadow: '0 0 20px #fff' },
            },
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Click To Get Started!</Typography>
        </Button>
      </Box>

      {/* RIGHT SIDE: empty on desktop to show background */}
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
          flex: '0 1 50%',
        }}
      />
    </Box>
  );
};

export default Hero;
