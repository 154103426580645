import React, { useContext, useRef, useState } from "react";
import CheckoutForm from "../../components/CheckoutForm";
import AddressForm from "../../components/AddressForm";
import { CartContext } from "../../CartContext";
import {
    Container,
    Button,
    Box,
    Typography,
    Divider,
    Card,
    CardMedia,
    CardContent,
    Collapse,
    useMediaQuery,
    useTheme,
    TextField,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import apiClient from "../../apiClient";

const CheckoutPage = () => {
    const {
        cart,
        discountCode,
        removeDiscount,
        setDiscountCode,
        discountPercentage,
        setDiscountPercentage,
        getDiscountedPrice,
    } = useContext(CartContext);

    const hasPhysicalProduct = cart.some(
        (item) => item.product_type === "Physical"
    );

    const addressFormRef = useRef(null);
    const [addressLocked, setAddressLocked] = useState(false);

    // State to show/hide the Order Summary on mobile
    const [showSummary, setShowSummary] = useState(false);

    // Local state for the input text field (so user can type something
    // before actually applying it)
    const [discountInput, setDiscountInput] = useState(discountCode);

    // Local UI error message for invalid or empty discount codes
    const [discountError, setDiscountError] = useState("");

    // Media query to detect mobile (sm and down). Adjust breakpoints as needed
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleContinueClick = async () => {
        const canDisable = addressFormRef.current?.handleDisableAllFields();
        if (canDisable) {
            setAddressLocked(true);
        }
    };

    const handleEditAddressClick = () => {
        addressFormRef.current?.enableAllFields();
        setAddressLocked(false);
    };

    // Mock API call to fetch discount percentage based on code
    // Replace this with your real API call
    const fetchDiscount = async (promoCode) => {
        if (!promoCode) return false; // Ensure input is valid

        try {
            const resp = await apiClient.post("/promo", { code: promoCode.toUpperCase() }, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (resp.status === 200 && resp.data?.isActive) {
                setDiscountCode(resp.data.promo.code);
                setDiscountPercentage(resp.data.promo.percentOff);
                return true;
            }
            return false; // Explicitly return false for invalid codes
        } catch (error) {
            console.error("Failed to fetch discount:", error);
            return false; // Return false on API errors
        }
    };

    // Handle apply discount
    const handleApplyDiscount = async () => {
        setDiscountError("");

        // 1) Check if user typed an empty code
        if (!discountInput) {
            setDiscountError("Enter a valid discount code");
            return;
        }

        if (!discountInput.trim()) {
            setDiscountError("Enter a valid discount code");
            return;
        }

        const is_active = await fetchDiscount(discountInput.trim())

        if (!is_active) {
            setDiscountError("Invalid Discount Code");
        }
    };

    // A small function to render the cart summary cards
    const renderCartSummary = () => {
        return (
            <Box
                sx={{
                    // Constrain width for nicer centering on desktop
                    width: "100%",
                    maxWidth: 500,
                    mx: "auto", // center horizontally
                }}
            >
                {cart.map((item, index) => {
                    const discountedPrice = getDiscountedPrice(item) * item.quantity;

                    // Determine if either an item discount or code discount is in effect
                    // (the context-level discountPercentage takes priority)
                    const effectiveDiscount =
                        discountPercentage > 0
                            ? discountPercentage
                            : item.discountPercentage || 0;

                    const hasDiscount = effectiveDiscount > 0;

                    return (
                        <Card
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 2,
                                p: 1,
                            }}
                        >
                            {/* IMAGE WRAPPER (with quantity circle) */}
                            <Box sx={{ position: "relative", mr: 2 }}>
                                <CardMedia
                                    component="img"
                                    image={"/images/child_book_icon.jpg"}
                                    alt={item.title || "Product Image"}
                                    sx={{ width: 60, height: 60 }}
                                />
                                {/* QUANTITY BUBBLE */}
                                {item.quantity > 1 && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            backgroundColor: "#ccc",
                                            borderRadius: "50%",
                                            width: 20,
                                            height: 20,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography variant="caption">{item.quantity}</Typography>
                                    </Box>
                                )}
                            </Box>

                            {/* CONTENT + PRICE (side by side) */}
                            <CardContent
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    width: "100%",
                                    p: 0,
                                }}
                            >
                                {/* Left side: Title & description */}
                                <Box>
                                    <Typography variant="subtitle1">
                                        {item.child_first_name}'s Children Book
                                    </Typography>
                                    <Typography
                                        color={'text.secondary'}
                                        variant={'subtitle2'}
                                        noWrap={true}
                                    >
                                        Gender:{' '}
                                        <Typography
                                            variant={'inherit'}
                                            component={'span'}
                                            color={'inherit'}
                                            fontWeight={700}
                                        >
                                            {item.gender}
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        color={'text.secondary'}
                                        variant={'subtitle2'}
                                        noWrap={true}
                                    >
                                        Age:{' '}
                                        <Typography
                                            variant={'inherit'}
                                            component={'span'}
                                            color={'inherit'}
                                            fontWeight={700}
                                        >
                                            {item.age}
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        color={'text.secondary'}
                                        variant={'subtitle2'}
                                        noWrap={true}
                                    >
                                        Type:{' '}
                                        <Typography
                                            variant={'inherit'}
                                            component={'span'}
                                            color={'inherit'}
                                            fontWeight={700}
                                        >
                                            {item.product_type === "Physical" ? `${item.physical_type} ` : `${item.product_type} `}Book
                                        </Typography>
                                    </Typography>
                                </Box>

                                {/* Right side: Price block */}
                                <Box sx={{ textAlign: "right" }}>
                                    {hasDiscount && (
                                        <>
                                            {/* Original Price (crossed out) */}
                                            <Typography
                                                variant="body2"
                                                sx={{ textDecoration: "line-through" }}
                                            >
                                                ${(item.price * item.quantity)?.toFixed(2) || "00.00"}
                                            </Typography>
                                            {/* Discount label */}
                                            <Typography variant="body2" color="red">
                                                {effectiveDiscount}% OFF
                                            </Typography>
                                            {/* New discounted price */}
                                            <Typography variant="body2" color="red">
                                                ${discountedPrice}
                                            </Typography>
                                        </>
                                    )}
                                    {!hasDiscount && (
                                        <Typography variant="body2">
                                            ${(item.price * item.quantity)?.toFixed(2) || "00.00"}
                                        </Typography>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    };

    // Renders the discount code UI
    const renderDiscountBox = () => {
        return (
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 500,
                    mx: "auto", // center horizontally on desktop
                    mt: 2,
                    mb: 4,
                }}
            >
                {/* Discount input + button */}
                <Box sx={{ display: "flex", gap: 1 }}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Discount Code"
                        variant="outlined"
                        value={discountInput}
                        onChange={(e) => setDiscountInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleApplyDiscount}>
                        Apply
                    </Button>
                </Box>

                {/* Error message if any */}
                {discountError && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        {discountError}
                    </Typography>
                )}

                {/* 1) The label for the applied discount code is now AFTER the text box & button */}
                {discountCode && (
                    <Box
                        sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            bgcolor: "#e0e0e0",
                            p: 1,
                            borderRadius: 1,
                            mt: 1,
                        }}
                    >
                        <Typography variant="body2" sx={{ mr: 1 }}>
                            Discount Code: <strong>{discountCode}</strong>
                        </Typography>
                        <IconButton
                            size="small"
                            onClick={removeDiscount}
                            sx={{ ml: "auto" }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                )}
            </Box>
        );
    };

    if (isMobile) {
        /** *******************************
         *      MOBILE LAYOUT
         *********************************/
        return (
            <Container>
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                    {/* 1) SHOW/HIDE ORDER SUMMARY BUTTON - FIRST THING ON MOBILE */}
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowSummary((prev) => !prev)}
                            fullWidth
                        >
                            {showSummary ? "Hide Order Summary" : "Show Order Summary"}
                        </Button>
                        <Collapse in={showSummary} timeout="auto" unmountOnExit>
                            <Box sx={{ mt: 2, bgcolor: "#f5f5f5", p: 2 }}>
                                {renderCartSummary()}
                                {/* Render discount box inside the summary on mobile */}
                                {renderDiscountBox()}
                            </Box>
                        </Collapse>
                    </Box>

                    {/* Checkout Flow */}
                    {/* If there's NO physical product, skip address flow entirely */}
                    {!hasPhysicalProduct && (
                        <Box sx={{ my: 2 }}>
                            {/* This form will re-render if it consumes discountCode/discountPercentage from context */}
                            <CheckoutForm />
                        </Box>
                    )}

                    {/* If there IS a physical product, show address + flow */}
                    {hasPhysicalProduct && (
                        <Box>
                            <AddressForm ref={addressFormRef} />

                            {/* Buttons */}
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                                {!addressLocked ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleContinueClick}
                                        sx={{ mr: 2 }}
                                    >
                                        Continue
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleEditAddressClick}
                                        sx={{ mr: 2 }}
                                    >
                                        Edit Address
                                    </Button>
                                )}
                            </Box>

                            {/* Show Checkout Form if addressLocked is true */}
                            {addressLocked && (
                                <Box sx={{ mt: 2 }}>
                                    {/* This form will re-render if it consumes discountCode/discountPercentage from context */}
                                    <CheckoutForm />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Container>
        );
    } else {
        /** *******************************
         *      DESKTOP LAYOUT
         *********************************/
        return (
            <Container>
                <Box sx={{ display: "flex", minHeight: "80vh", mt: 2 }}>
                    {/* LEFT SIDE (50%) */}
                    <Box sx={{ width: "50%", pr: 2 }}>
                        {/* If there's NO physical product, skip address flow entirely */}
                        {!hasPhysicalProduct && (
                            <Box sx={{ my: 2 }}>
                                <CheckoutForm />
                            </Box>
                        )}

                        {/* If there IS a physical product, show address + flow */}
                        {hasPhysicalProduct && (
                            <>
                                {/* Address Form */}
                                <AddressForm ref={addressFormRef} />

                                {/* Buttons */}
                                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                                    {!addressLocked ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleContinueClick}
                                            sx={{ mr: 2 }}
                                        >
                                            Continue
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleEditAddressClick}
                                            sx={{ mr: 2 }}
                                        >
                                            Edit Address
                                        </Button>
                                    )}
                                </Box>

                                {/* Show Checkout Form if addressLocked is true */}
                                {addressLocked && (
                                    <Box sx={{ mt: 2 }}>
                                        <CheckoutForm />
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>

                    {/* VERTICAL DIVIDER */}
                    <Divider orientation="vertical" flexItem />

                    {/* RIGHT SIDE (50%) with light grey background */}
                    <Box
                        sx={{
                            width: "50%",
                            pl: 2,
                            bgcolor: "#f5f5f5",
                            minHeight: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center horizontally
                            pt: 2
                        }}
                    >
                        {/* 1) Order Summary (centered) */}
                        {renderCartSummary()}

                        {/* 2) Horizontal divider AFTER the order summary (non-mobile only) */}
                        <Divider sx={{ width: "100%", my: 2 }} />

                        {/* 3) Discount Box */}
                        {renderDiscountBox()}
                    </Box>
                </Box>
            </Container>
        );
    }
};

export default CheckoutPage;
