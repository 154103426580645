import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsOfService = () => {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Typography variant="h1" gutterBottom sx={{ fontWeight: "bold" }}>
                Terms of Service
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Effective Date: 01/01/2025
            </Typography>

            <Box sx={{ mt: 3 }}>
                {[
                    { title: "1. Acceptance of Terms", content: "By accessing and using the website located at https://www.makeabook.ai, you agree to comply with and be bound by these Terms of Service (\"Terms\"). If you do not agree, please do not use our Website or services." },
                    { title: "2. Description of Service", content: "Make A Book Inc. provides personalized digital and physical books created based on user-submitted information, including children's names, ages, and photos." },
                    { title: "3. Customer Information and Privacy", content: "You agree to provide accurate, current, and complete information. We securely store names and ages indefinitely and uploaded photos for a maximum of six months through Amazon Web Services (AWS). Our Privacy Policy details data handling practices." },
                    { title: "4. Intellectual Property Rights", content: "All intellectual property rights in personalized books created by Make A Book Inc. remain exclusively ours. Customers may share or resell physical and digital books but may not reproduce or copy them for resale or commercial purposes." },
                    { title: "5. Payment and Pricing", content: "Payments via accepted methods are final. Contact help@makeabook.ai if issues arise with your book." },
                    { title: "6. Subscription Services", content: "Our annual subscription offers a 40% discount on physical books and ten (10) free digital books monthly. Subscriptions auto-renew annually and fees are non-refundable." },
                    { title: "7. Shipping and Delivery", content: "Physical book shipping is managed by third-party fulfillment providers. We assist customers with related inquiries through help@makeabook.ai." },
                    { title: "8. User Obligations", content: "You agree: Not to submit false or misleading information. Not to use our services unlawfully or in a manner infringing intellectual property or privacy rights. To promptly report unauthorized access or security concerns." },
                    { title: "9. Prohibited Use", content: "Make A Book Inc. explicitly prohibits using this service to create books or content that are offensive, defamatory, abusive, obscene, discriminatory, harmful, or illegal. Any misuse or illegal activity is the sole responsibility of the user, and Make A Book Inc. will not be held liable." },
                    { title: "10. Data Modification and Deletion", content: "Currently, data modification or deletion requests are not supported but will be available soon." },
                    { title: "11. Limitation of Liability", content: "Make A Book Inc. will not be liable for indirect, incidental, consequential, or exemplary damages from the use of the Website or products." },
                    { title: "12. Disclaimer", content: "We do not guarantee absolute accuracy, completeness, or usefulness of personalized content and will not be responsible for errors or omissions." },
                    { title: "13. Arbitration", content: "Disputes shall be resolved through binding arbitration per arbitration rules of the American Arbitration Association (AAA), with proceedings held in New York, NY." },
                    { title: "14. Governing Law", content: "These Terms are governed by the laws of New York and the United States." },
                    { title: "15. International Users", content: "Users outside the United States, including Canada and the UK, consent to data processing and storage within the United States." },
                    { title: "16. Contact", content: "For questions, concerns, or claims, contact us by email at: help@makeabook.ai. By using our services, you acknowledge reading, understanding, and agreeing to these Terms." },
                ].map((section, index) => (
                    <Box key={index} sx={{ mb: 3 }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                            {section.title}
                        </Typography>
                        <Typography variant="body1">{section.content}</Typography>
                    </Box>
                ))}
            </Box>
        </Container>
    );
};

export default TermsOfService;
