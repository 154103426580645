import * as React from 'react';
import { useAuth } from '../AuthContext';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { CartContext } from "../CartContext";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { Typography, Divider } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontSize: '0.75rem',
    height: '16px',
    minWidth: '16px',
    right: 0,
    top: 0,
    transform: 'translate(50%, -50%)',
    backgroundColor: '#ff6961', // Light red color
    color: 'white'
  },
}));

export default function NavBar() {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cartCount, setCartCount] = useState(0);
  const { cart, globalDiscountCode, globalDiscountPercentage } = useContext(CartContext);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setCartCount(cart.length);
  }, [cart]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleNavigateHome = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    // Outer container: ensure full width, no margin on left/right
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        background: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        width: '100%',
        margin: 0,
        padding: 0
      }}
    >
      {/* MAIN NAVBAR ROW */}
      <Box
        sx={{
          position: 'relative',
          minHeight: 56,
          display: 'flex',
          alignItems: 'center',
          // Make the navbar slightly slimmer, but remove left/right padding:
          py: 1
        }}
      >
        {/* DESKTOP: Logo + text pinned left */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            display: { xs: 'none', sm: 'flex' }, // Show only on sm and up
            alignItems: 'center',
            ml: 3
          }}
        >
          <IconButton onClick={handleNavigateHome} sx={{ p: 0 }}>
            <img
              src={'/images/makeabooklogo.png'}
              style={{ width: '40px', height: '40px' }}
              alt='Make A Book Logo'
            />
          </IconButton>
          <Button
            onClick={handleNavigateHome}
            sx={{ ml: 1, fontWeight: 'bold', textTransform: 'none' }}
          >
            MakeABook
          </Button>
        </Box>

        {/* MOBILE: Hamburger + (centered) Logo + text */}
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            position: 'relative',
            alignItems: 'center',
            width: '100%',
            minHeight: 56
          }}
        >
          {/* Hamburger on the left */}
          <IconButton
            onClick={handleMenuOpen}
            sx={{ position: 'absolute', left: 0 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Centered Logo + "MakeABook" */}
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleNavigateHome} sx={{ p: 0 }}>
              <img
                src={'/images/makeabooklogo.png'}
                style={{ width: '40px', height: '40px' }}
                alt='Make A Book Logo'
              />
            </IconButton>
            <Button
              onClick={handleNavigateHome}
              sx={{ ml: 1, fontWeight: 'bold', textTransform: 'none' }}
            >
              MakeABook
            </Button>
          </Box>
        </Box>

        {/* DESKTOP: Nav links centered */}
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: 'rgba(0,0,0,0.2)', borderWidth: '1px' }}
              />
            }
          >
            <Button
              variant="text"
              onClick={() => navigate("/children-books")}
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
            >
              Children Books
            </Button>
            <Button
              variant="text"
              onClick={() => navigate("/pricing")}
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
            >
              Pricing & Shipping
            </Button>
            <Button
              variant="text"
              onClick={() => navigate("/order-status")}
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
            >
              Order Status
            </Button>

            {isLoggedIn ? (
              <>
                <Button
                  variant="text"
                  onClick={() => navigate("/orders")}
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                >
                  Orders
                </Button>
                <Button
                  variant="text"
                  onClick={logout}
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                >
                  Logout
                </Button>
              </>
            ) : null /* <-- Return null so no extra divider */}
          </Stack>
        </Box>

        {/* RIGHT SECTION: Cart icon pinned far right (both mobile & desktop) */}
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => navigate("/cart")}
            sx={{ mr: 1 }}
          >
            <StyledBadge
              badgeContent={cartCount}
              color="secondary"
              overlap="circular"
              showZero
            >
              <ShoppingCartIcon />
            </StyledBadge>
          </IconButton>
        </Box>
      </Box>

      {/* BANNER: Only shows if discount code/percentage exist */}
      {globalDiscountCode && globalDiscountPercentage && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'lightblue',
            textAlign: 'center',
            // Remove extra spacing beneath banner:
            py: 1,
            m: 0
          }}
        >
          <Typography variant="body1" sx={{ margin: 0 }}>
            SAVE {globalDiscountPercentage}% | CODE: <b>{globalDiscountCode}</b>
          </Typography>
        </Box>
      )}

      {/* MOBILE MENU (Hamburger Items) */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigate("/children-books");
          }}
        >
          Children Books
        </MenuItem>
        {isLoggedIn ? (
          <>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                navigate("/orders");
              }}
            >
              Orders
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
                handleMenuClose();
              }}
            >
              Logout
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                navigate("/order-status");
              }}
            >
              Order Status
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                navigate("/pricing");
              }}
            >
              Pricing & Shipping
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
}
