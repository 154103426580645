import React, { useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { capitalizeFirstLetter } from "../../utils/stringUtils";

// A sub-component to render each row (including its collapsible content)
function Row({ item, expandByDefault }) {
  const [open, setOpen] = useState(expandByDefault);

  return (
    <>
      {/* Main row (always visible) */}
      <TableRow>
        {/* Expand/Collapse toggle cell */}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {/* Email */}
        <TableCell>
          <Typography variant="subtitle2" fontWeight={700}>
            {item?.email || ''}
          </Typography>
        </TableCell>

        {/* Status */}
        <TableCell>
          <Typography color="text.secondary" variant="subtitle2">
            {item?.status || ''}
          </Typography>
        </TableCell>

        {/* Date */}
        <TableCell>
          <Typography variant="caption" fontWeight={700}>
            {new Date(item?.createdAt).toDateString() || ''}
          </Typography>
        </TableCell>

        {/* Price */}
        <TableCell>
          <Typography color="text.secondary" variant="subtitle2">
            {(item.totalPrice / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) || ''}
          </Typography>
        </TableCell>
      </TableRow>

      {/* Collapsible details row */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* You can customize how items are displayed here */}
              {item?.items && item.items.length > 0 && (
                <Table size="small" aria-label="item details">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Book Link</b></TableCell>
                      <TableCell><b>Child First Name</b></TableCell>
                      <TableCell><b>Age</b></TableCell>
                      <TableCell><b>Prompt</b></TableCell>
                      <TableCell><b>Gender</b></TableCell>
                      <TableCell><b>Book Type</b></TableCell>
                      <TableCell><b>Quantity</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.items.map((childItem, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {childItem?.pdfLink ? (
                            <a href={childItem.pdfLink} target="_blank" rel="noopener noreferrer">
                              View PDF
                            </a>
                          ) : (
                            'Not yet created'
                          )}
                        </TableCell>
                        <TableCell>{childItem?.childFirstName || ''}</TableCell>
                        <TableCell>{childItem?.age || ''}</TableCell>
                        <TableCell>{childItem?.prompt || ''}</TableCell>
                        <TableCell>{childItem?.gender || ''}</TableCell>
                        <TableCell>{
                          childItem.physicalType
                            ? `${capitalizeFirstLetter(childItem.productType)} - ${capitalizeFirstLetter(childItem.physicalType)}`
                            : childItem.physicalType
                        }</TableCell>
                        <TableCell>{childItem?.quantity || ''}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const Simple = ({ items = [] }) => {
  console.log(items)
  const [expandByDefault] = useState(false);

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: 'alternate.dark' }}>
            <TableRow>
              <TableCell />
              <TableCell>
                <Typography
                  variant="caption"
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="caption"
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="caption"
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="caption"
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Price
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <Row key={index} item={item} expandByDefault={expandByDefault} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Simple;
