import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar.jsx';
import Footer from './components/Footer.jsx';
import ReturnPage from './pages/ReturnPage/ReturnPage.js';
import ChildBookPage from './pages/ChildBookPage/ChildBookPage.js';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage.js';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage.js';
import { AuthProvider } from './AuthContext.js';
import { FormDataProvider } from './FormDataContext';
import { CartProvider } from './CartContext';
import { HomePage, CartPage, CheckoutPage, OrderStatusPage, PricingPage, TOSPage, PrivacyPolicyPage } from './pages';

import './App.css';

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <FormDataProvider>
          <BrowserRouter>
            <NavBar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/children-books" element={<ChildBookPage />} />
              <Route path="/return" element={<ReturnPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/order-status" element={<OrderStatusPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/tos" element={<TOSPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/cancel" element={<h2>Payment Cancelled</h2>} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </FormDataProvider>
      </CartProvider>
    </AuthProvider >
  );
}

export default App;
