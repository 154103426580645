import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CartContext } from "../../../../CartContext";
import { useNavigate } from 'react-router-dom';

const SummeryBox = () => {
  const {
    cart,
    discountCode,
    setDiscountCode,
    discountPercentage,
    setDiscountPercentage,
  } = useContext(CartContext);

  const navigate = useNavigate();

  // Local state for discount code input and error message
  const [discountInput, setDiscountInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Calculate subtotal by summing (price * quantity) for each item
  const subtotal = cart.reduce((total, item) => {
    const itemPrice = item.price;
    return total + itemPrice * (item.quantity || 1);
  }, 0);

  // Calculate discount amount
  const discountAmount = discountPercentage > 0 ? (subtotal * discountPercentage) / 100 : 0;

  // Final order total after discount
  const orderTotal = subtotal - discountAmount;

  // A default function to verify the discount code
  // This function returns an object containing isActive, code, percentOff
  const verifyDiscountCode = (code) => {
    // Example logic for demonstration:
    if (code === 'SAVE10') {
      return { isActive: true, code, percentOff: 10 };
    }
    // Add additional code checks as needed
    return { isActive: false, code: '', percentOff: 0 };
  };

  const handleApplyDiscount = () => {
    const trimmedCode = discountInput.trim();
    const result = verifyDiscountCode(trimmedCode);

    if (!result.isActive) {
      setErrorMessage('Invalid discount code');
    } else {
      // Valid code: set global discount data, clear error
      setDiscountCode(result.code);
      setDiscountPercentage(result.percentOff);
      setErrorMessage('');
    }
  };

  // Remove discount handler
  const handleRemoveDiscount = () => {
    setDiscountCode('');
    setDiscountPercentage(0);
    setErrorMessage('');
  };

  return (
    <Box>
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        sx={{
          marginY: 4,
          '& .MuiInputBase-input.MuiOutlinedInput-input': {
            bgcolor: 'background.paper',
          },
        }}
      >
        <Box>
          {/* If discountCode is active, show the label + remove button */}
          {discountCode && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {discountCode}
              </Typography>
              <Button variant="text" onClick={handleRemoveDiscount}>
                x
              </Button>
            </Box>
          )}

          <TextField
            label="Discount code"
            variant="outlined"
            color="primary"
            fullWidth
            height={54}
            value={discountInput}
            onChange={(e) => setDiscountInput(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <Button
            color="primary"
            size="large"
            fullWidth
            sx={{
              bgcolor: 'divider',
              color: 'text.primary',
              marginTop: 1,
            }}
            onClick={handleApplyDiscount}
          >
            Apply the code
          </Button>
        </Box>
      </Box>

      <Stack spacing={2} marginY={{ xs: 2, sm: 4 }}>
        {/* Subtotal */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'text.secondary'}>Subtotal</Typography>
          <Typography color={'text.secondary'} fontWeight={700}>
            ${subtotal.toFixed(2)}
          </Typography>
        </Box>

        {/* Discount */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'text.secondary'}>Discount</Typography>
          <Typography color={'text.secondary'} fontWeight={700}>
            -${discountAmount.toFixed(2)}
          </Typography>
        </Box>

        {/* Sales Tax */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography color={'text.secondary'}>Sales Tax</Typography>
          <Typography color={'text.secondary'} fontWeight={700}>
            Calculated at checkout
          </Typography>
        </Box>

        <Divider />

        {/* Order total */}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h6'} fontWeight={700}>
            Order total
          </Typography>
          <Typography variant={'h6'} fontWeight={700}>
            ${orderTotal.toFixed(2)}
          </Typography>
        </Box>

        {/* Checkout button */}
        <Button
          variant={'contained'}
          size={'large'}
          onClick={() => navigate("/checkout")}
          fullWidth
        >
          Checkout
        </Button>
      </Stack>
    </Box>
  );
};

export default SummeryBox;
