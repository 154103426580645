import React, { useContext, useState } from "react";
import {
    Stepper,
    Step,
    StepLabel,
    TextField,
    Button,
    Tooltip,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Card,
    Grid as Grid2,
    Radio,
    RadioGroup,
    FormControlLabel,
    Snackbar,
    Box,
    Container,
    FormControl,
} from "@mui/material";

import MuiAlert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormDataContext } from "../../FormDataContext";
import { CartContext } from "../../CartContext";
import apiClient from "../../apiClient";
import { boxStyle } from "../../utils/constants.js";
import { useNavigate } from "react-router-dom";

// Rename the MUI Grid to Grid2 in import and use it as 'Grid' below for clarity
const Grid = Grid2;

// A convenience wrapper for MUI Alert
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={1} ref={ref} variant="filled" {...props} />;
});

const ages = [
    {
        title: "Toddler",
        description: "12 months to 3 years old",
        value: "Toddler",
        icon: (
            <img
                src="/images/toddler.png"
                alt="Icon"
                width={72}
                height={48}
            />
        ),
    },
    {
        title: "Preschooler",
        description: "3 to 5 years old",
        value: "Preschooler",
        icon: (
            <img
                src="/images/preschooler.png"
                alt="Icon"
                width={72}
                height={48}
            />
        ),
    },
    {
        title: "School-Aged",
        description: "5 to 12 years old",
        value: "School-Aged",
        icon: (
            <img
                src="/images/schoolaged.png"
                alt="Icon"
                width={72}
                height={48}
            />
        ),
    },
];

const genders = [
    {
        title: "Boy",
        description: "",
        value: "Boy",
        icon: (
            <img
                src="/images/baby_boy_icon.jpg"
                alt="Icon"
                width={48}
                height={48}
            />
        ),
    },
    {
        title: "Girl",
        description: "",
        value: "Girl",
        icon: (
            <img
                src="/images/baby_girl_icon.jpg"
                alt="Icon"
                width={48}
                height={48}
            />
        ),
    },
];

// Steps for the entire wizard
const getSteps = () => {
    return ["Fill in Child Info", "Upload Picture", "Choose Book", "Review"];
};

const columnBoxStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "auto",
    paddingTop: 10,
    paddingBottom: 5,
};

// Reusable field with tooltip and error display
const TooltipField = ({
    label,
    tooltip,
    value,
    onChange,
    error,
    helperText,
    ...props
}) => (
    <Box sx={{ display: "flex", flexDirection: "column", margin: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
                label={label}
                value={value || ""}
                onChange={onChange}
                error={error}
                helperText={helperText}
                {...props}
            />
            {tooltip && (
                <Tooltip title={tooltip}>
                    <IconButton aria-label={`${label}-info`}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    </Box>
);

/**
 * Renders the content for the currently active step.
 */
const StepContent = ({
    step,
    handleNext,
    handlePrev,
    handleChange,
    handleFileChange,
    selectedImage,
    formData,
    errors,
    step0SubStep,
    setStep0SubStep,
    setErrors,
    setPhysicalDialogOpen,
    handleAddToCart,
    handleCloseDialog,
}) => {
    // STEP 0 SUB-STEP NAV
    // sub-steps:
    // 0 => child's first name
    // 1 => child's age
    // 2 => child's gender
    // 3 => child's prompt
    if (step === 0) {
        const handleSubStepNext = () => {
            let newErrors = {};

            switch (step0SubStep) {
                case 0: {
                    const nameVal = formData.child_first_name;
                    // Validate child's first name
                    if (!nameVal) {
                        newErrors.child_first_name = "Please enter child's first name.";
                    } else if (/\s/.test(nameVal)) {
                        // Check if there's any whitespace => not allowed
                        newErrors.child_first_name =
                            "Child's first name must not contain spaces or multiple words.";
                    }
                    break;
                }
                case 1: {
                    // Validate age
                    if (!formData.age) {
                        newErrors.age = "Please select an age.";
                    }
                    break;
                }
                case 2: {
                    // Validate gender
                    if (!formData.gender) {
                        newErrors.gender = "Please select a gender.";
                    }
                    break;
                }
                case 3: {
                    const promptVal = formData.prompt || "";
                    // Validate prompt length
                    if (promptVal.length < 25) {
                        newErrors.prompt = "Prompt must be at least 25 characters.";
                    } else if (promptVal.length > 200) {
                        newErrors.prompt = "Prompt must be at most 200 characters.";
                    }
                    break;
                }
                default:
                    break;
            }

            // If we have any errors, set them and do NOT proceed
            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                return;
            } else {
                // Clear errors for these fields
                setErrors({});
            }

            // If last sub-step, move to next main step
            if (step0SubStep >= 3) {
                handleNext(); // proceed to step 1
            } else {
                setStep0SubStep(step0SubStep + 1);
            }
        };

        // When user presses "Back" or "Cancel" on sub-steps
        const handleSubStepBack = () => {
            if (step0SubStep > 0) {
                setStep0SubStep(step0SubStep - 1);
            } else {
                // Instead of handlePrev, close dialog entirely
                handleCloseDialog();
            }
        };

        const renderSubStepContent = () => {
            switch (step0SubStep) {
                case 0:
                    return (
                        <>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
                                What is the child's name?
                            </Typography>
                            <TooltipField
                                label="Child's First Name"
                                tooltip="Enter the child's first name. No spaces or multiple words allowed."
                                value={formData.child_first_name}
                                onChange={(e) => {
                                    // As soon as user types, remove error if present
                                    if (errors.child_first_name) {
                                        setErrors((prev) => ({ ...prev, child_first_name: "" }));
                                    }
                                    handleChange("child_first_name", e.target.value);
                                }}
                                error={!!errors.child_first_name}
                                helperText={errors.child_first_name || ""}
                            />
                        </>
                    );
                case 1:
                    return (
                        <>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
                                What is the child's age?
                            </Typography>
                            <FormControl component="fieldset" sx={{ width: 1, marginTop: 4 }}>
                                <RadioGroup
                                    value={formData.age}
                                    name="radio-buttons-group"
                                    onChange={(e) => {
                                        if (errors.age) {
                                            setErrors((prev) => ({ ...prev, age: "" }));
                                        }
                                        handleChange("age", e.target.value);
                                    }}
                                    sx={{ width: 1 }}
                                >
                                    <Grid container spacing={2}>
                                        {ages.map((item, i) => (
                                            <Grid key={i} item xs={12} sm={6} md={4}>
                                                <Card sx={{ p: 2, height: "100%" }}>
                                                    <FormControlLabel
                                                        value={item.value}
                                                        control={<Radio />}
                                                        label={
                                                            <Box
                                                                display={"flex"}
                                                                flexDirection={"column"}
                                                                alignItems={"center"}
                                                                height={1}
                                                            >
                                                                <Box marginBottom={4}>{item.icon}</Box>
                                                                <Typography fontWeight={700} align={"center"}>
                                                                    {item.title}
                                                                </Typography>
                                                                <Typography align={"center"} color={"text.secondary"}>
                                                                    {item.description}
                                                                </Typography>
                                                                <Box flexGrow={1} />
                                                            </Box>
                                                        }
                                                        sx={{
                                                            width: 1,
                                                            height: 1,
                                                            margin: 0,
                                                            display: "flex",
                                                            flexDirection: "column-reverse",
                                                            "& .MuiFormControlLabel-label": {
                                                                width: "100%",
                                                                height: "100%",
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </RadioGroup>
                                {errors.age && (
                                    <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                        {errors.age}
                                    </Typography>
                                )}
                            </FormControl>
                        </>
                    );
                case 2:
                    return (
                        <>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
                                What is the child's gender?
                            </Typography>
                            <FormControl
                                component="fieldset"
                                sx={{ width: 1, marginTop: 4 }}
                                error={!!errors.gender}
                            >
                                <RadioGroup
                                    value={formData.gender}
                                    name="radio-buttons-group"
                                    sx={{ width: 1 }}
                                    onChange={(e) => {
                                        if (errors.gender) {
                                            setErrors((prev) => ({ ...prev, gender: "" }));
                                        }
                                        handleChange("gender", e.target.value);
                                    }}
                                >
                                    <Grid container spacing={2} justifyContent="center">
                                        {genders.map((item, i) => (
                                            <Grid key={i} item xs={12} sm={6} md={4}>
                                                <Card sx={{ p: 2, height: "100%" }}>
                                                    <FormControlLabel
                                                        value={item.value}
                                                        control={<Radio />}
                                                        label={
                                                            <Box
                                                                display={"flex"}
                                                                flexDirection={"column"}
                                                                alignItems={"center"}
                                                                height={1}
                                                            >
                                                                <Box marginBottom={4}>{item.icon}</Box>
                                                                <Typography fontWeight={700} align={"center"}>
                                                                    {item.title}
                                                                </Typography>
                                                                <Typography align={"center"} color={"text.secondary"}>
                                                                    {item.description}
                                                                </Typography>
                                                                <Box flexGrow={1} />
                                                            </Box>
                                                        }
                                                        sx={{
                                                            width: 1,
                                                            height: 1,
                                                            margin: 0,
                                                            display: "flex",
                                                            flexDirection: "column-reverse",
                                                            "& .MuiFormControlLabel-label": {
                                                                width: "100%",
                                                                height: "100%",
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </RadioGroup>
                                {errors.gender && (
                                    <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                                        {errors.gender}
                                    </Typography>
                                )}
                            </FormControl>
                        </>
                    );
                case 3:
                    return (
                        <>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
                                What would you like the story to be about?
                            </Typography>
                            <TooltipField
                                label="Prompt"
                                tooltip="Write a short prompt for the book. Minimum 25 characters, maximum 200."
                                value={formData.prompt}
                                multiline
                                rows={3}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    // Enforce max length
                                    if (val.length > 200) {
                                        val = val.slice(0, 200);
                                    }
                                    handleChange("prompt", val);
                                    // If there's an existing prompt error, clear it on change
                                    if (errors.prompt) {
                                        setErrors((prev) => ({ ...prev, prompt: "" }));
                                    }
                                }}
                                error={!!errors.prompt}
                                helperText={
                                    errors.prompt
                                        ? errors.prompt
                                        : `Character count: ${formData.prompt ? formData.prompt.length : 0
                                        }/200`
                                }
                                sx={{ minWidth: "50%" }}
                                inputProps={{
                                    style: { resize: "vertical", overflow: "auto" },
                                    maxLength: 200,
                                }}
                            />
                            <Typography variant="h6" sx={{ mb: 2, pt: 4, fontWeight: "bold" }}>
                                Examples of prompts:
                            </Typography>
                            <Typography sx={{ p: 1 }}>
                                Write a book about Michael who wants to be a basketball player.
                            </Typography>
                            <Typography sx={{ p: 1 }}>
                                Write a book about Sarah who wants to be the first woman president.
                            </Typography>
                            <Typography sx={{ pt: 1, pr: 1, pl: 1 }}>
                                Write a book about Gary who wants to be an astronaut and explore space.
                            </Typography>
                        </>
                    );
                default:
                    return null;
            }
        };

        return (
            <Box sx={boxStyle}>
                {renderSubStepContent()}
                <Box sx={columnBoxStyle}>
                    {step0SubStep > 0 ? (
                        <Button
                            variant="contained"
                            onClick={handleSubStepBack}
                            sx={{ margin: 1, fontWeight: "bold" }}
                        >
                            Back
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleCloseDialog}
                            sx={{ margin: 1, fontWeight: "bold" }}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleSubStepNext}
                        sx={{ margin: 1, fontWeight: "bold" }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        );
    }

    // STEP 1 - Photo is required
    if (step === 1) {
        return (
            <Box sx={boxStyle}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Upload Child's Photo
                    </Typography>
                    <Tooltip title="This photo will be used to create a character for the book. Please ensure it is a clear selfie of only the child.">
                        <IconButton aria-label="upload-info">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                {/* Example Photos Section */}
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        overflowX: "auto",
                        gap: 2,
                        mb: 3,
                        justifyContent: "center",
                    }}
                >
                    {/* Good Example 1 */}
                    <Box sx={{ minWidth: 120 }}>
                        <Card
                            sx={{
                                position: "relative",
                                p: 1,
                                width: 120,
                                height: 240,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src="/images/good_child_photo1.jpg"
                                    alt="Good Example 1"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Box>
                            <CheckCircleIcon
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    margin: "4px",
                                    color: "green",
                                }}
                            />
                            <Typography
                                variant="caption"
                                align="center"
                                display="block"
                                sx={{ mt: 1 }}
                            >
                                <b>Good Photo</b>: Clearly shows face, good lighting, front-facing.
                            </Typography>
                        </Card>
                    </Box>

                    {/* Bad Example */}
                    <Box sx={{ minWidth: 120 }}>
                        <Card
                            sx={{
                                position: "relative",
                                p: 1,
                                width: 120,
                                height: 240,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src="/images/bad_child_photo1.jpg"
                                    alt="Bad Example"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            </Box>
                            <CancelIcon
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    margin: "4px",
                                    color: "red",
                                }}
                            />
                            <Typography
                                variant="caption"
                                align="center"
                                display="block"
                                sx={{ mt: 1 }}
                            >
                                <b>Bad Photo</b>: Child's face isn't clear. Multiple people in photo.
                            </Typography>
                        </Card>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        "& > input": {
                            display: "none",
                        },
                    }}
                >
                    <input
                        accept="image/*"
                        type="file"
                        id="contained-button-file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span" sx={{
                            animation: 'glow 1.5s infinite alternate',
                            '@keyframes glow': {
                                '0%': { boxShadow: '0 0 5px #0000FF' },
                                '100%': { boxShadow: '0 0 20px #0000FF' },
                            },
                            fontWeight: "bold"
                        }}>
                            Upload Photo
                        </Button>
                    </label>
                    {selectedImage && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Uploaded Image:
                            </Typography>
                            <img
                                src={selectedImage}
                                alt="Preview"
                                style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                        </Box>
                    )}
                    {/* Show error under photo if any */}
                    {errors.selectedImage && (
                        <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                            {errors.selectedImage}
                        </Typography>
                    )}
                    <Box sx={columnBoxStyle}>
                        <Button
                            variant="contained"
                            onClick={handlePrev}
                            sx={{ margin: 1, fontWeight: "bold" }}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ margin: 1, fontWeight: "bold" }}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }

    // STEP 2
    if (step === 2) {
        return (
            <Box sx={boxStyle}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Choose Your Book
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            textAlign: "center",
                            flex: 1,
                            marginRight: { sm: 2, xs: 0 },
                            marginBottom: { xs: 2, sm: 0 },
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                // Set to digital
                                // Price is 29.95
                                setErrors({});
                                handleChange("product_type", "Digital");
                                handleChange("price", 29.95);
                                // Move on
                                handleNext();
                            }}
                            sx={{ width: "100%", fontWeight: "bold" }}
                        >
                            Digital - $29.95
                        </Button>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Includes PDF version of the book, accessible anytime online.
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", flex: 1, marginLeft: { sm: 2, xs: 0 } }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                // Set to physical
                                // Price is 49.95
                                setErrors({});
                                handleChange("product_type", "Physical");
                                handleChange("price", 49.95);
                                // Open the physical copy selection modal
                                setPhysicalDialogOpen(true);
                            }}
                            sx={{ width: "100%", fontWeight: "bold" }}
                        >
                            Physical - $49.95
                        </Button>
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Includes PDF version of the book and a physical saddle stitched copy.
                        </Typography>
                    </Box>
                </Box>
                <Box sx={columnBoxStyle}>
                    <Button
                        variant="contained"
                        onClick={handlePrev}
                        sx={{ margin: 1, fontWeight: "bold" }}
                    >
                        Back
                    </Button>
                </Box>
            </Box>
        );
    }

    // STEP 3
    if (step === 3) {
        return (
            <Box sx={boxStyle}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Review Your Book Info
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <TextField
                        label="First Name - Age - Gender"
                        value={`${formData.child_first_name} - ${formData.age} - ${formData.gender}`}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="filled"
                    />
                    <TextField
                        label="Prompt"
                        value={formData.prompt || ""}
                        multiline
                        rows={2}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="filled"
                    />
                    <TextField
                        label="Book Type"
                        value={
                            formData.product_type === "Physical"
                                ? `${formData.product_type} - ${formData.physical_type}`
                                : formData.product_type
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="filled"
                    />

                    {selectedImage && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Uploaded Photo:
                            </Typography>
                            <img
                                src={selectedImage}
                                alt="Uploaded"
                                style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        onClick={handleAddToCart}
                        sx={{
                            animation: 'glow 1.5s infinite alternate',
                            '@keyframes glow': {
                                '0%': { boxShadow: '0 0 5px #0000FF' },
                                '100%': { boxShadow: '0 0 20px #0000FF' },
                            },
                            fontWeight: "bold"
                        }}
                    >
                        Add to Cart
                    </Button>
                </Box>

                <Box sx={columnBoxStyle}>
                    <Button
                        variant="contained"
                        onClick={handlePrev}
                        sx={{ margin: 1, fontWeight: "bold" }}
                    >
                        Back
                    </Button>
                </Box>
            </Box>
        );
    }

    // Should not happen if steps are correct
    return null;
};

const ChildBookPage = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [step0SubStep, setStep0SubStep] = useState(0);

    const [formData, setFormData] = useContext(FormDataContext);
    const { addToCart } = useContext(CartContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const steps = getSteps();
    const navigate = useNavigate();

    // Main Dialog / Wizard
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);

    // Reset wizard when closing
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setActiveStep(0);
        setStep0SubStep(0);
        setErrors({});
    };

    // Dialog for physical copy selection
    const [physicalDialogOpen, setPhysicalDialogOpen] = useState(false);
    const [tempPhysicalChoice, setTempPhysicalChoice] = useState("Paperback"); // default
    const handleClosePhysicalDialog = () => {
        setPhysicalDialogOpen(false);
    };
    const handleConfirmPhysicalChoice = () => {
        // Save selection to the form
        setFormData((prev) => ({
            ...prev,
            physical_type: tempPhysicalChoice,
        }));
        // Close the modal and move to next wizard step
        setPhysicalDialogOpen(false);
        handleNext();
    };

    // Post Add-to-Cart success dialog
    const [postAddToCartDialogOpen, setPostAddToCartDialogOpen] = useState(false);

    // Snackbar states (used mainly for quick error or info messages)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error"); // can be error or success

    const showError = (message) => {
        setSnackbarSeverity("error");
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    // Form data change handlers
    const handleChange = (field, value) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            const data = new FormData();
            data.append("file", file);

            try {
                const response = await apiClient.post("/upload", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (response && response.data) {
                    const img_id = response.data.image_id || response.data.imageId;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        img_id: img_id,
                    }));
                }
            } catch (error) {
                console.error("Error uploading file:", error);
                showError("Error uploading file. Please try again.");
            }
        }
    };

    // Validates the entire step (except step 0, which is done with sub-steps).
    const validateStep = (stepIndex) => {
        let isValid = true;
        let newErrors = {};

        // Step 1: must have an image
        if (stepIndex === 1) {
            if (!selectedImage) {
                isValid = false;
                newErrors.selectedImage = "Please upload a photo before proceeding.";
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    // Next/Prev steps
    const handleNext = () => {
        // Validate current step
        if (!validateStep(activeStep)) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // Add to Cart logic
    const handleAddToCart = () => {
        // If user chose hardcover
        if (formData.product_type === "Physical" && formData.physical_type === "Hardcover") {
            formData.price += 10.0;
        }

        addToCart({ ...formData, quantity: 1 });
        // Show success modal
        setPostAddToCartDialogOpen(true);
    };

    const resetPage = () => {
        // Reset wizard and form data
        setFormData({});
        setSelectedImage(null);
        setActiveStep(0);
        setStep0SubStep(0);
        setErrors({});
        setOpenDialog(false);
    }

    const handleGoToCart = () => {
        setPostAddToCartDialogOpen(false);
        resetPage();
        navigate("/cart");
    };

    const handleCreateAnotherBook = () => {
        setPostAddToCartDialogOpen(false);
        // Reset wizard and form data
        resetPage();
        setTimeout(() => {
            setOpenDialog(true);
        }, 300);
    };

    return (
        <Container>
            {/* Page Hero */}
            <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography variant="h3" sx={{ fontWeight: "bold" }} gutterBottom>
                    Create Your Child's Book
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2, height: 400 }}>
                    <img
                        src="/images/create_book2.png"
                        alt="Placeholder"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    size="lg"
                    sx={{
                        animation: "glow 1.5s infinite alternate",
                        "@keyframes glow": {
                            "0%": { boxShadow: "0 0 5px #00008B" },
                            "100%": { boxShadow: "0 0 20px #00008B" },
                        },
                        fontWeight: "bold",
                    }}
                >
                    Start Creating
                </Button>
            </Box>

            {/* Main Dialog / Wizard */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                sx={{
                    "& .MuiDialog-container": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                }}
                PaperProps={{
                    sx: {
                        width: "80%",
                        height: "80%",
                    },
                }}
            >
                <DialogContent>
                    <Box sx={{ width: "100%", mt: 2 }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        <StepContent
                            step={activeStep}
                            handleNext={handleNext}
                            handlePrev={handlePrev}
                            handleChange={handleChange}
                            handleFileChange={handleFileChange}
                            selectedImage={selectedImage}
                            formData={formData}
                            errors={errors}
                            step0SubStep={step0SubStep}
                            setStep0SubStep={setStep0SubStep}
                            setErrors={setErrors}
                            setPhysicalDialogOpen={setPhysicalDialogOpen}
                            handleAddToCart={handleAddToCart}
                            handleCloseDialog={handleCloseDialog}
                        />
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Modal for choosing paperback or hardcover */}
            <Dialog open={physicalDialogOpen} onClose={handleClosePhysicalDialog}>
                <DialogTitle>Select Physical Format</DialogTitle>
                <DialogContent>
                    <Typography sx={{ mb: 2 }}>
                        Please choose which type of physical copy you would like:
                    </Typography>
                    <RadioGroup
                        value={tempPhysicalChoice}
                        onChange={(e) => setTempPhysicalChoice(e.target.value)}
                    >
                        <FormControlLabel
                            value="Paperback"
                            control={<Radio />}
                            label="Paperback Copy"
                        />
                        <FormControlLabel
                            value="Hardcover"
                            control={<Radio />}
                            label="Hardcover Copy - + $10.00"
                        />
                    </RadioGroup>

                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                        <Button variant="outlined" onClick={handleClosePhysicalDialog}>
                            Back
                        </Button>
                        <Button variant="contained" onClick={handleConfirmPhysicalChoice}>
                            Confirm
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Post "Add to Cart" Success Dialog */}
            <Dialog
                open={postAddToCartDialogOpen}
                onClose={() => setPostAddToCartDialogOpen(false)}
            >
                <DialogTitle>Item Added to Cart!</DialogTitle>
                <DialogContent>
                    <Typography sx={{ mb: 2 }}>
                        Your item has been successfully added to the cart.
                    </Typography>
                    {/* 
                      UPDATED BOX to ensure the two buttons are the same size, 
                      centered horizontally, with a bit of space between them 
                    */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            mt: 2,
                            "& button": {
                                minWidth: "150px",
                                minHeight: "40px",
                            },
                        }}
                    >
                        <Button variant="outlined" onClick={handleGoToCart}>
                            Go To Cart
                        </Button>
                        <Button variant="contained" onClick={handleCreateAnotherBook}>
                            Create Another Book
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            {/* Snackbar (for errors or info) */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ChildBookPage;
