import React from "react";
import { Container, Typography, Box, Paper } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Privacy Policy
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Effective Date: 01/01/2025
                </Typography>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Introduction</Typography>
                    <Typography>
                        Make A Book Inc. ("we," "us," "our") values your privacy and is
                        committed to protecting the personal information of our customers
                        and their families. This Privacy Policy explains how we collect,
                        use, share, store, and protect your personal data through our
                        website at <a href="https://makeabook.ai">makeabook.ai</a>
                        ("Website"). By accessing or using our Website, you agree to this
                        Privacy Policy.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Information We Collect</Typography>
                    <Typography>
                        We collect the following personal information through online forms
                        on our Website:
                    </Typography>
                    <ul>
                        <li>Child's Name and Age: Used to personalize books.</li>
                        <li>
                            Child's Photo: Used as reference material for creating
                            illustrations and personalized content in books. Photos are
                            retained for six (6) months from the date of submission and then
                            securely deleted.
                        </li>
                        <li>
                            Payment Information: Processed securely through third-party
                            payment gateways; we do not store your payment details.
                        </li>
                        <li>
                            Contact Information (Name, Email, Shipping Address): Collected
                            for billing, delivery, customer support, and marketing
                            communications.
                        </li>
                    </ul>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Use of Personal Information</Typography>
                    <ul>
                        <li>Creating and delivering personalized digital and physical books.</li>
                        <li>Processing payments and fulfilling orders.</li>
                        <li>Communicating with you regarding your orders and services.</li>
                        <li>Improving our Website and customer experience.</li>
                        <li>
                            Marketing purposes (with an opt-out option by emailing
                            help@makeabook.ai).
                        </li>
                    </ul>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Data Retention</Typography>
                    <Typography>
                        Names and ages provided for personalization are stored indefinitely
                        unless otherwise requested. Child photos are stored securely for no
                        longer than six (6) months and are permanently deleted thereafter.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Data Storage and Security</Typography>
                    <Typography>
                        We store data securely using Amazon Web Services (AWS). We
                        implement industry-standard security measures to protect your data
                        against unauthorized access, alteration, disclosure, or
                        destruction.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Third-Party Sharing</Typography>
                    <Typography>
                        We may share your data with third-party fulfillment services,
                        payment processors, and marketing services. We never sell personal
                        data to third parties for their marketing or commercial use.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Customer Rights</Typography>
                    <Typography>
                        Currently, options to directly modify or delete personal data are
                        in development. Requests can be submitted via help@makeabook.ai.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Cookies and Analytics</Typography>
                    <Typography>
                        We use cookies and analytics tools to enhance and optimize our
                        website performance and customer experience.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Compliance and Jurisdiction</Typography>
                    <Typography>
                        We comply with applicable privacy laws in the United States and
                        will extend compliance to the UK and Canada as we expand
                        operations. Disputes related to this policy will be resolved
                        through arbitration governed by the laws of New York.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Changes to Privacy Policy</Typography>
                    <Typography>
                        We reserve the right to update this Privacy Policy periodically.
                        Changes will be communicated via updates on our Website.
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Contact Us</Typography>
                    <Typography>
                        For questions, requests, or concerns, please contact us at:
                        <br />
                        <strong>Email:</strong> help@makeabook.ai
                        <br />
                        <strong>Website:</strong> <a href="https://makeabook.ai">makeabook.ai</a>
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Dispute Resolution</Typography>
                    <Typography>
                        All disputes arising from or related to this Privacy Policy shall
                        be resolved through binding arbitration under the jurisdiction of
                        New York, New York, USA.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
