import { React, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CartContext } from "../../../../CartContext";

const Orders = () => {
  const { cart, updateCartQuantity, removeFromCart, discountPercentage } = useContext(CartContext);
  const theme = useTheme();

  return (
    <Box>
      {cart.map((item, i) => {
        const hasDiscount = discountPercentage > 0;
        // Price calculations
        const originalTotal = item.price * item.quantity;
        const discountedPrice = item.price * (1 - discountPercentage / 100);
        const discountedTotal = discountedPrice * item.quantity;

        return (
          <Box key={item.id}>
            <Box display={'flex'}>
              <Box
                component={'img'}
                src="/images/child_book_icon.jpg"
                alt={item.title}
                sx={{
                  borderRadius: 2,
                  width: 1,
                  height: 1,
                  maxWidth: { xs: 120, sm: 160 },
                  marginRight: 2,
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                }}
              />
              <Box
                display={'flex'}
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
                width={1}
              >
                <Box sx={{ order: 1 }}>
                  <Typography fontWeight={700} gutterBottom>
                    Custom Children's Book
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    variant={'subtitle2'}
                    gutterBottom
                  >
                    Name:{' '}
                    <Typography
                      variant={'inherit'}
                      component={'span'}
                      color={'inherit'}
                      fontWeight={700}
                    >
                      {item.child_first_name}
                    </Typography>
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    variant={'subtitle2'}
                    gutterBottom
                  >
                    Gender:{' '}
                    <Typography
                      variant={'inherit'}
                      component={'span'}
                      color={'inherit'}
                      fontWeight={700}
                    >
                      {item.gender}
                    </Typography>
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    variant={'subtitle2'}
                    noWrap={true}
                    gutterBottom
                  >
                    Age:{' '}
                    <Typography
                      variant={'inherit'}
                      component={'span'}
                      color={'inherit'}
                      fontWeight={700}
                    >
                      {item.age}
                    </Typography>
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    variant={'subtitle2'}
                    noWrap={true}
                    gutterBottom
                  >
                    Type:{' '}
                    <Typography
                      variant={'inherit'}
                      component={'span'}
                      color={'inherit'}
                      fontWeight={700}
                    >
                      {item.product_type}
                      {item.product_type === "Physical" ? ` - ${item.physical_type}` : ""}
                    </Typography>
                  </Typography>
                </Box>

                {/* Remove button */}
                <Stack
                  spacing={1}
                  direction={{ xs: 'row', sm: 'column' }}
                  marginTop={{ xs: 2, sm: 0 }}
                  sx={{ order: { xs: 3, sm: 2 } }}
                >
                  <Button
                    size="small"
                    onClick={() => removeFromCart(item.id)}
                  >
                    <Box
                      component={'svg'}
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      marginRight={0.5}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </Box>
                    Remove
                  </Button>
                </Stack>

                {/* Quantity + Price */}
                <Stack
                  spacing={1}
                  direction={'row'}
                  alignItems={'center'}
                  marginTop={{ xs: 2, sm: 0 }}
                  sx={{ order: { xs: 2, sm: 3 } }}
                >
                  <FormControl fullWidth>
                    <Select
                      value={item.quantity}
                      onChange={(e) => updateCartQuantity(item.id, e.target.value)}
                      sx={{
                        '& .MuiSelect-select': {
                          paddingY: 0.5,
                        },
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Price display with discount logic */}
                  <Box
                    display={'flex'}
                    flexDirection={{ xs: 'row', sm: 'column' }}
                    alignItems={{ xs: 'center', sm: 'flex-start' }}
                  >
                    <Typography
                      fontWeight={700}
                      sx={{
                        textDecoration: hasDiscount ? 'line-through' : 'none',
                        marginRight: { xs: 1, sm: 0 },
                      }}
                    >
                      ${originalTotal.toFixed(2)}
                    </Typography>
                    {hasDiscount && (
                      <Typography
                        fontWeight={700}
                        sx={{ color: 'red', fontSize: '0.9rem' }}
                      >
                        ${discountedTotal.toFixed(2)}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Divider
              sx={{
                marginY: { xs: 2, sm: 4 },
                display: i === cart.length - 1 ? 'none' : 'block',
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default Orders;
