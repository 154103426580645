import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import { Orders, SummeryBox } from './components';

import { Container } from "@mui/material";

const CartWithOrderSummery = () => {
  return (
    <Container>
      <Box>
        <Grid container spacing={{ xs: 4, md: 8 }} sx={{ pt: 4 }}>
          <Grid item xs={12} md={8}>
            <Orders />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              sx={{
                bgcolor: 'alternate.main',
                padding: { xs: 2, sm: 4 },
              }}
            >
              <SummeryBox />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CartWithOrderSummery;
