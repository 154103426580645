import React, {
    useContext,
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import {
    Autocomplete as MUIAutocomplete,
    TextField,
    Box,
    MenuItem,
    Grid,
} from "@mui/material";
import { CartContext } from "../CartContext";

// -- COUNTRY OPTIONS --
const COUNTRY_OPTIONS = [
    { code: "US", label: "United States" },
    { code: "GB", label: "United Kingdom" },
];

// -- UK MAPPING --
// If we interpret your instructions literally:
//   key = "England", label = "ENG"
const UK_STATE_MAPPING = {
    "England": "ENG",
    "Scotland": "SCT",
    "Wales": "WLS",
    "Northern Ireland": "NIR",
};

// Convert the UK_STATE_MAPPING object into an array for a select
const UK_STATES = Object.entries(UK_STATE_MAPPING).map(([key, value]) => ({
    code: value,  // e.g. "England"
    name: key // e.g. "ENG"
}));

// -- US STATES + TERRITORIES --
const US_STATES = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
    // Territories / District
    { code: "DC", name: "District of Columbia" },
    { code: "AS", name: "American Samoa" },
    { code: "GU", name: "Guam" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "PR", name: "Puerto Rico" },
    { code: "UM", name: "U.S. Minor Outlying Islands" },
    { code: "VI", name: "U.S. Virgin Islands" },
];

const AddressForm = forwardRef((props, ref) => {
    const { address, updateAddress } = useContext(CartContext);

    // State for inputs
    const [selectedCountry, setSelectedCountry] = useState("US");
    const [options, setOptions] = useState([]);
    const [editableAddress, setEditableAddress] = useState({
        firstName: address.firstName || "",
        lastName: address.lastName || "",
        street1: address.street1 || "",
        street2: address.street2 || "",
        city: address.city || "",
        state: address.state || "",
        postcode: address.postcode || "",
    });

    // State for errors (true if the corresponding field is invalid/empty)
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        street1: false,
        city: false,
        state: false,
        postcode: false,
    });

    const [isDisabled, setIsDisabled] = useState(false);
    const autocompleteService = useRef(null);

    useEffect(() => {
        if (!window.google || !window.google.maps || !window.google.maps.places) return;
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }, []);

    // Expose functions to the parent so that it can disable/enable fields
    useImperativeHandle(ref, () => ({
        handleDisableAllFields,
        enableAllFields,
    }));

    const handleDisableAllFields = () => {
        const requiredFields = ["firstName", "lastName", "street1", "city", "state", "postcode"];
        let newErrors = { ...errors };
        let hasError = false;

        requiredFields.forEach((field) => {
            if (editableAddress[field].trim() === "") {
                newErrors[field] = true;
                hasError = true;
            } else {
                newErrors[field] = false;
            }
        });

        setErrors(newErrors);

        if (hasError) {
            return false; // Missing required fields
        } else {
            setIsDisabled(true);
            return true; // All required fields filled
        }
    };

    const enableAllFields = () => {
        setIsDisabled(false);
    };

    // Handle the country change
    const handleCountryChange = (event) => {
        const newCountry = event.target.value;
        setSelectedCountry(newCountry);

        // Clear relevant fields
        const clearedAddress = {
            firstName: editableAddress.firstName,
            lastName: editableAddress.lastName,
            street1: "",
            street2: "",
            city: "",
            state: "",
            postcode: "",
        };
        setEditableAddress(clearedAddress);
        setOptions([]);
        updateAddress({ ...clearedAddress, country: newCountry });
    };

    // For text field changes, we do real-time validation on required fields
    const handleFieldChange = (field) => (event) => {
        const updatedValue = event.target.value;
        const newAddress = { ...editableAddress, [field]: updatedValue };

        setEditableAddress(newAddress);

        if (field !== "street2") {
            // Mark error if empty for required fields
            setErrors((prev) => ({
                ...prev,
                [field]: updatedValue.trim() === "",
            }));
        }

        updateAddress({ ...newAddress, country: selectedCountry });
    };

    // Handle Autocomplete input changes (replacing street1 TextField)
    const handleInputChange = (event, value) => {
        setEditableAddress((prev) => ({
            ...prev,
            street1: value,
        }));
        setErrors((prev) => ({
            ...prev,
            street1: value.trim() === "",
        }));

        updateAddress({ ...editableAddress, street1: value, country: selectedCountry });

        if (!value) {
            setOptions([]);
            return;
        }

        if (autocompleteService.current) {
            autocompleteService.current.getPlacePredictions(
                { input: value, componentRestrictions: { country: selectedCountry } },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                        setOptions(
                            predictions.map((prediction) => ({
                                description: prediction.description,
                                place_id: prediction.place_id,
                            }))
                        );
                    } else {
                        setOptions([]);
                    }
                }
            );
        }
    };

    // Handle selection from the Autocomplete list
    const handlePlaceSelect = async (event, selectedOption) => {
        if (!selectedOption) return;

        const placeId = selectedOption.place_id;
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ placeId }, (results, status) => {
            if (status === "OK" && results[0]) {
                const place = results[0];

                const newAddress = {
                    ...editableAddress, // keep firstName/lastName
                    street2: "",
                    city: "",
                    state: "",
                    postcode: "",
                };

                let finalStreet1 = "";

                const stateComponent = place.address_components.find((component) =>
                    component.types.includes("administrative_area_level_1")
                );

                const cityComponent = place.address_components.find((component) =>
                    component.types.includes("locality")
                );

                // US logic
                if (selectedCountry === "US") {
                    if (stateComponent) {
                        newAddress.state = stateComponent.short_name; // ex: "NY"
                        // Handling special case for NY sublocalities
                        if (stateComponent.short_name === "NY") {
                            const sublocality = place.address_components.find((component) =>
                                component.types.includes("sublocality")
                            );
                            const sublocality_level_1 = place.address_components.find((component) =>
                                component.types.includes("sublocality_level_1")
                            );

                            if (sublocality) {
                                newAddress.city = sublocality.long_name;
                            } else if (sublocality_level_1) {
                                newAddress.city = sublocality_level_1.long_name;
                            }
                        } else if (cityComponent) {
                            newAddress.city = cityComponent.long_name;
                        }
                    }
                }
                // UK logic
                else if (selectedCountry === "GB") {
                    if (stateComponent) {
                        // The "short_name" might be "England", "Scotland", etc.
                        // So set address.state to that key, e.g. "England"
                        newAddress.state = UK_STATE_MAPPING[stateComponent.short_name];
                    }

                    const postal_town = place.address_components.find((component) =>
                        component.types.includes("postal_town")
                    );

                    if (postal_town) {
                        newAddress.city = postal_town.long_name;
                    }
                }

                place.address_components.forEach((component) => {
                    const types = component.types;

                    if (types.includes("street_number")) {
                        finalStreet1 = component.long_name + " " + finalStreet1;
                    }
                    if (types.includes("route")) {
                        finalStreet1 += component.long_name;
                    }

                    if (types.includes("postal_code")) {
                        newAddress.postcode = component.long_name;
                    }
                });

                newAddress.street1 = finalStreet1.trim();

                // Validate required fields
                const newErrors = {
                    ...errors,
                    street1: newAddress.street1.trim() === "",
                    city: newAddress.city.trim() === "",
                    state: newAddress.state.trim() === "",
                    postcode: newAddress.postcode.trim() === "",
                };
                setErrors(newErrors);

                setEditableAddress(newAddress);
                updateAddress({ ...newAddress, country: selectedCountry });
            }
        });
    };

    // Determine which state options to show based on country
    const isUS = selectedCountry === "US";
    const isUK = selectedCountry === "GB";

    let stateOptions = [];
    if (isUS) {
        // Use US_STATES array
        stateOptions = US_STATES;
    } else if (isUK) {
        // Use UK_STATES (derived from UK_STATE_MAPPING)
        stateOptions = UK_STATES;
    }

    return (
        <Box sx={{ maxWidth: 500, margin: "auto", padding: 2 }}>
            {/* Use a Box with consistent vertical spacing */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {/* Country Selection */}
                <TextField
                    select
                    fullWidth
                    label="Select Country"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    variant="outlined"
                    disabled={isDisabled}
                >
                    {COUNTRY_OPTIONS.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Address Autocomplete (Street Address) */}
                <MUIAutocomplete
                    freeSolo
                    options={options}
                    value={
                        editableAddress.street1
                            ? { description: editableAddress.street1 }
                            : null
                    }
                    getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.description || ""
                    }
                    onInputChange={handleInputChange}
                    onChange={handlePlaceSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Street Address"
                            variant="outlined"
                            fullWidth
                            disabled={isDisabled}
                            error={errors.street1}
                            helperText={errors.street1 ? "Street Address is required" : ""}
                        />
                    )}
                />

                {/* First Name & Last Name */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            disabled={isDisabled}
                            value={editableAddress.firstName}
                            onChange={handleFieldChange("firstName")}
                            error={errors.firstName}
                            helperText={errors.firstName ? "First Name is required" : ""}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            disabled={isDisabled}
                            value={editableAddress.lastName}
                            onChange={handleFieldChange("lastName")}
                            error={errors.lastName}
                            helperText={errors.lastName ? "Last Name is required" : ""}
                        />
                    </Grid>
                </Grid>

                {/* Street2 (not required) */}
                <TextField
                    fullWidth
                    label="Street 2 (Apartment, Suite, etc.)"
                    variant="outlined"
                    disabled={isDisabled}
                    value={editableAddress.street2}
                    onChange={handleFieldChange("street2")}
                    placeholder="Apartment, suite, unit, etc. (optional)"
                />

                {/* City, State, and Postal Code */}
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            label="City"
                            variant="outlined"
                            disabled={isDisabled}
                            value={editableAddress.city}
                            onChange={handleFieldChange("city")}
                            error={errors.city}
                            helperText={errors.city ? "City is required" : ""}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        {/* State as a select field */}
                        <TextField
                            select
                            fullWidth
                            label="State"
                            variant="outlined"
                            disabled={isDisabled}
                            value={editableAddress.state}
                            onChange={handleFieldChange("state")}
                            error={errors.state}
                            helperText={errors.state ? "State is required" : ""}
                        >
                            <MenuItem value="">
                                <em>-- Select --</em>
                            </MenuItem>
                            {stateOptions.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Postal Code"
                            variant="outlined"
                            disabled={isDisabled}
                            value={editableAddress.postcode}
                            onChange={handleFieldChange("postcode")}
                            error={errors.postcode}
                            helperText={errors.postcode ? "Postal Code is required" : ""}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
});

export default AddressForm;
