import React, { useContext } from 'react';
import { EmptyCart, CartWithOrderSummery } from '../../components';
import { CartContext } from "../../CartContext";

const CartPage = () => {
    const { cart } = useContext(CartContext);

    return cart.length === 0 ? <EmptyCart /> : <CartWithOrderSummery />;
};

export default CartPage;
