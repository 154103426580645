import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";

const mock = [
  {
    title: 'The stories you want',
    subtitle:
      'The story you imagine for your child is now effortless to create. Tell us what you want, and we’ll turn it into a custom book your child will love.',
    image: '/images/creativity.jpg',
  },
  {
    title: 'Unique and engaging narratives',
    subtitle:
      'Everything from the illustrations to the story are engaging, well-structured and completely unique for you.',
    image: '/images/engaging.jpg',
  },
  {
    title: 'The greatest character',
    subtitle:
      'A character for your story using the image and information you provide. Get the most unique story possible. Created for you, by you.',
    image: '/images/char_photo.jpg',
  },
];

const SimpleFeaturesWithAlternateCards = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box>
        <Box marginBottom={4}>
          <Typography fontWeight={700} variant={'h4'}>
            What makes it different?
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Card
                  width={1}
                  height={1}
                  display={'flex'}
                  flexDirection={'column'}
                  bgcolor={'alternate.main'}
                >
                  {/* CardMedia for the top image */}
                  <CardMedia
                    component="img"
                    image={item.image}
                    alt={item.title}
                    title={item.title}
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      height: 267,           // ~2/3 of 400px
                      objectFit: 'cover',    // Maintains the layout nicely
                    }}
                  />
                  <CardContent>
                    <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {item.subtitle}
                    </Typography>
                  </CardContent>
                  <Box flexGrow={1} />
                  <CardActions sx={{ justifyContent: 'flex-end', paddingX: 4 }}>
                    <Button size="large" onClick={() => navigate("children-books")}>Create Yours</Button>
                  </CardActions>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default SimpleFeaturesWithAlternateCards;
