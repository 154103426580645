import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import React from 'react';

import {
    Reviews,
    NewsletterWithDarkBackground,
    SimpleFeaturesWithAlternateCards,
    SimpleLeftAligned,
    HeroWithFormAndBackgroundGradient
} from '../../components';

const HomePage = () => {
    return (
        <Box>
            <Box pb={{ xs: 4, sm: 6, md: 8 }}>
                <Container>
                    <HeroWithFormAndBackgroundGradient />
                </Container>
            </Box>
            <Box bgcolor={'#f7faff'} py={{ xs: 4, sm: 6, md: 8 }}>
                <Container>
                    <SimpleLeftAligned />
                </Container>
            </Box>
            <Box py={{ xs: 4, sm: 6, md: 8 }}>
                <Container>
                    <SimpleFeaturesWithAlternateCards />
                </Container>
            </Box>
            <Box bgcolor={'#f7faff'} py={{ xs: 4, sm: 6, md: 8 }}>
                <Container>
                    <Reviews />
                </Container>
            </Box>
            <Box py={{ xs: 4, sm: 6, md: 8 }}>
                <Container>
                    <NewsletterWithDarkBackground />
                </Container>
            </Box>
        </Box>
    );
};

export default HomePage;
