import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import apiClient from '../../apiClient';
import Container from "@mui/material/Container";

const NewsletterWithDarkBackground = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const handleSubscribe = async () => {
    if (!email || !email.includes('@')) {
      setSubscriptionStatus('Please enter a valid email address.');
      return;
    }
    try {
      const response = await apiClient.post('/mailing-list', JSON.stringify({ email }), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        setSubscriptionStatus('Thank you for subscribing!');
        setEmail(''); // Optional: Clear email input after successful subscription
      } else {
        setSubscriptionStatus('Failed to subscribe. Please try again.');
      }
    } catch (error) {
      console.error('Subscription error:', error);
      setSubscriptionStatus('Error subscribing. Please check your network and try again.');
    }
  };

  return (
    <Container>
      <Box component={Card} boxShadow={3} paddingY={3} bgcolor={'primary.main'}>
        <CardContent>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box marginBottom={4}>
              <Typography
                variant="h4"
                align={'center'}
                gutterBottom
                sx={{ fontWeight: 700, color: 'common.white' }}
              >
                Subscribe to our newsletter
              </Typography>
              <Typography
                variant="h6"
                align={'center'}
                color={'text.secondary'}
                sx={{ color: 'common.white' }}
              >
                Don't lose a chance to be among the firsts to know about our
                upcoming news and updates.
              </Typography>
            </Box>
            <Box
              width={1}
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
              justifyContent={'center'}
            >
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  maxWidth: { xs: 1, sm: 400 },
                  width: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },
                  '& .MuiInputBase-root': {
                    color: 'white',
                  },
                  '& .MuiInputAdornment-root svg': {
                    color: 'white !important',
                  },
                }}
              >
                <TextField
                  id="outlined-basic"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                  aria-label="Enter your email address"
                  placeholder="Your email address"
                  value={email || ''}
                  onChange={e => setEmail(e.target.value)}
                />
              </FormControl>
              <Box
                component={Button}
                variant="contained"
                bgcolor="common.white"
                color="primary.main"
                size="large"
                maxWidth="100%"
                fullWidth={isSm ? false : true}
                marginTop={{ xs: 2, sm: 0 }}
                marginLeft={{ sm: 2 }}
                height={54}
                endIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                }
                onClick={handleSubscribe}
              >
                Subscribe
              </Box>
            </Box>
            {subscriptionStatus && (
              <Box marginTop={3} textAlign="center">
                <Typography variant="body1" sx={{ color: 'common.white' }}>
                  {subscriptionStatus}
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Box>
    </Container>
  );
};

export default NewsletterWithDarkBackground;
