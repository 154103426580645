import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../../CartContext';
import { Navigate } from 'react-router-dom';
import apiClient from '../../apiClient';
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';
import { boxStyle } from '../../utils/constants';

const ReturnPage = () => {
    const { cart, clearCart, clearAddress } = useContext(CartContext);
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [sessionId, setSessionId] = useState('');

    useEffect(() => {
        // Extract session_id from the URL
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const retrievedSessionId = urlParams.get('session_id');
        setSessionId(retrievedSessionId);

        if (retrievedSessionId) {
            apiClient
                .get('/session-status', { params: { session_id: retrievedSessionId } })
                .then(response => {
                    if (response && response.data) {
                        setStatus(response.data.status);
                        setCustomerEmail(response.data.email);
                    }
                })
                .catch(error => console.error('Error fetching session status:', error));
        }
    }, []);

    useEffect(() => {
        if (status === 'complete' && sessionId) {
            console.log(cart)
            clearCart();
            clearAddress();
        }
    }, [status, cart, clearAddress, clearCart, sessionId]);

    if (status === 'open') {
        return <Navigate to="/children-books" />;
    }

    if (status === "complete") {
        return (
            <Box sx={boxStyle}>
                <section id="success">
                    <Typography
                        level="h1"
                        fontWeight="xl"
                        fontSize="clamp(3.875rem, 1.3636rem + 2.1818vw, 3rem)"
                        textAlign="center"
                    >
                        Thank you for your order!<br /><br />
                    </Typography>
                    <Typography
                        level='h2'
                        fontWeight="xl"
                        textAlign="center"
                    >
                        We appreciate your business! A confirmation email will be sent to {customerEmail}.
                    </Typography>

                    <br /><br />
                    <Typography
                        level='h3'
                        textAlign="center"
                    >
                        If you have any questions, please email <a href="mailto:orders@makeabook.ai">orders@makeabook.ai</a>.
                    </Typography>
                </section>
            </Box>
        );
    }

    return null;
};

export default ReturnPage;
