import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { Simple } from "../../components";
import apiClient from "../../apiClient";
import { Typography } from "@mui/material";

const OrderStatusPage = () => {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [items, setItems] = useState([]);

    const fetchItems = async (email, confirmationCode) => {
        try {
            const response = await apiClient.get("/api/order", {
                params: { email, confirmationCode },
            });

            if (response.status === 200 && response.data) {
                setItems([response.data.order]);
            } else {
                setItems([]);
            }
        } catch (error) {
            console.error("Error fetching items:", error);
            setItems([]);
        }
    };

    useEffect(() => {
        const emailParam = searchParams.get("e");
        const confirmationParam = searchParams.get("cc");

        if (emailParam && confirmationParam) {
            setEmail(emailParam);
            setConfirmationCode(confirmationParam);
        }
    }, [searchParams]);

    const handleFindOrder = async () => {
        await fetchItems(email, confirmationCode);
    };

    return (
        <Container
            sx={{
                mt: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Typography variant="h3" sx={{ fontWeight: "bold", pb: 4 }}>
                Order Status
            </Typography>
            {items.length === 0 ? (
                <Stack
                    spacing={2}
                    sx={{
                        width: "100%",
                        maxWidth: 400,
                    }}
                    alignItems="center"
                >
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Confirmation Code"
                        variant="outlined"
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)}
                        fullWidth
                    />
                    <Button variant="contained" onClick={handleFindOrder}>
                        Find Order
                    </Button>
                </Stack>
            ) : (
                <Simple items={items} />
            )}
        </Container>
    );
};

export default OrderStatusPage;
