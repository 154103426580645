import Container from "@mui/material/Container";
import React from 'react';

import { WithOptionTogglerButton } from '../../components';

const PricingPage = () => {
    return (
        <Container>
            <WithOptionTogglerButton />
        </Container>
    );
};

export default PricingPage;
